import { Locale, type LocalizedString } from "../Locale.js";
import { ProductSize } from "../ProductSize.js";

export const productSizeNameMap: Record<ProductSize, LocalizedString> = {
	[ProductSize.Xs]: {
		[Locale.cs]: "XS",
	},
	[ProductSize.S]: {
		[Locale.cs]: "S",
	},
	[ProductSize.M]: {
		[Locale.cs]: "M",
	},
	[ProductSize.L]: {
		[Locale.cs]: "L",
	},
};
